const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 4524 4524"
    fill="none"
  >
    <path
      opacity="0.64"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2916.6 926.057C3018.26 529.322 2779.05 125.291 2382.32 23.6296C1985.58 -78.0321 1581.55 161.172 1479.89 557.908C1382.78 936.867 1333.34 1332.08 1337.33 1735.3C1197.69 1719.19 1060.43 1693.65 926.271 1659.27C529.535 1557.61 125.505 1796.81 23.8429 2193.55C-77.8188 2590.28 161.386 2994.32 558.121 3095.98C896.112 3182.59 1247.01 3231.2 1604.87 3237.88C1725.18 3575.02 1883.05 3892.13 2072.74 4184.93C2295.43 4528.66 2754.59 4626.78 3098.31 4404.1C3442.04 4181.41 3540.16 3722.25 3317.48 3378.52C3242.16 3262.27 3174.23 3140.3 3114.51 3013.11C3497.92 2888.53 3856.82 2715.83 4185.15 2503.12C4528.87 2280.44 4626.99 1821.28 4404.31 1477.55C4181.62 1133.83 3722.46 1035.71 3378.74 1258.39C3205.83 1370.41 3020.3 1466.07 2823.76 1542.74C2833.84 1331.96 2865.46 1125.62 2916.6 926.057Z"
      fill="white"
    />
  </svg>
);

const Smile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20%"
      height="20%"
      viewBox="0 0 838 557"
      fill="none"
    >
      <path
        d="M794.381 291.777C794.381 291.777 660.431 517.702 353.767 513.134C135.867 509.924 43.5215 371.159 43.5215 371.159"
        stroke="#231F20"
        strokeWidth="86.1979"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M461.546 102.148C461.546 102.148 497.595 235.974 596.607 213.999C695.619 192.024 685.495 44 685.495 44"
        stroke="#231F20"
        strokeWidth="86.1979"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M340.061 122.027C340.061 122.027 348.579 260.421 247.716 271.038C146.852 281.656 109.198 138.2 109.198 138.2"
        stroke="#231F20"
        strokeWidth="86.1979"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Sausage = () => {
  const fontSize = Math.max(
    Math.floor(0.054882080670046 * window.innerHeight - 28.631915362574),
    24
  );

  return (
    <div className="absolute inset-0 flex flex-col gap-4 items-center justify-center py-5 overflow-hidden">
      <div
        className="text-2xl bg-amber-400/75 rounded-lg px-5 py-3 font-bold flex-none"
        style={{
          fontSize: `${fontSize}px`,
          padding: `${fontSize / 2}px ${fontSize}px`,
          marginBottom: `${fontSize}px`,
        }}
      >
        EXPRESS RELIEF: 204 CR
      </div>

      <div className="flex-1 relative">
        {/* <SVG /> */}
        <div className="inset-0 flex flex-col items-center justify-center">
          {/* <Smile /> */}

          <img
            src="/tv-bg.png"
            style={{ height: "70vh", width: "auto", marginTop: "40px" }}
          />
        </div>
      </div>
    </div>
  );
};
