import { ModalSvg } from "./ModalSvg";
import { Overlay } from "../app2/Overlay";

export default function Modal({ content }: { content?: string }) {
  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center">
      <Overlay />
      <div
        className="relative w-full max-w-xl z-10 rounded-2xl"
        style={{ aspectRatio: 1.3 }}
      >
        <div className="absolute inset-0 z-0">
          <ModalSvg />
        </div>
        <div className="absolute flex justify-center items-center font-medium z-10 left-8 right-8 top-8 bottom-0">
          {content ??
            `THANKYOU FOR SUBMITTING YOUR PLEDGE. LOOK AT THE SCREEN FOR A
          SURPRISE!`}
        </div>
      </div>
    </div>
  );
}
