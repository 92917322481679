import "./styles.css";
import App from "./app";
import App2 from "./app2";

export const Router = () => {
  const url = window.location.pathname;
  if (url === "/update-feed") {
    return <App2 />;
  }
  return <App />;
};
