export const ModalSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 353 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_95_8962)">
        <g filter="url(#filter0_d_95_8962)">
          <rect width="100%" height="100%" rx="9" fill="#FFD100" />
        </g>
        <path
          d="M204.019 266H391C391 266 372.456 216 343.927 216C315.398 216 306.454 250.507 306.454 250.507C306.454 250.507 287.197 231.759 271.39 231.759C255.584 231.759 241.82 256.541 241.82 256.541C241.82 256.541 219.479 243.217 204 266H204.019Z"
          fill="#EBEBEB"
        />
        <path
          d="M269 266H448C448 266 417.257 207.864 383.893 207.012C350.542 206.16 333 251.02 333 251.02C333 251.02 281.112 209.768 269 266Z"
          fill="#F5DB78"
        />
        <path
          d="M43.2847 239.743L44.8955 256.64L54.5781 245.445L86.0216 219L59.0312 227.888C59.0312 227.888 45.772 234.543 45.1673 235.411C44.5683 236.273 43.2841 239.736 43.2841 239.736L43.2847 239.743Z"
          fill="#DD5339"
        />
        <path
          d="M28.7881 232.661L86.022 219.006L43.2845 239.742L28.7881 232.661Z"
          fill="#F37458"
        />
        <path
          d="M65.5211 251.985L47.7139 243.385L86.0223 219.007L65.5211 251.985Z"
          fill="#F37458"
        />
        <path
          d="M39.4838 243.51C39.4838 243.51 -2.81189 252.097 -2.42824 277.458C-2.04459 302.819 32.9548 312.106 39.9926 285.514C47.0304 258.921 -11.239 211.944 -46.3325 251.946C-66.7479 275.218 -82.2997 296.538 -104.471 304.789"
          stroke="#231F20"
          strokeWidth="0.461985"
          strokeMiterlimit="10"
        />
        <g opacity="0.52">
          <path
            d="M177.001 61.9834L191.891 61.8758L195.613 61.8481H199.334C201.817 61.8758 204.297 61.8973 206.78 61.9834C206.79 61.9834 206.796 61.9895 206.796 61.9987C206.796 62.0079 206.79 62.0141 206.78 62.0141C204.297 62.1032 201.817 62.1247 199.334 62.1524H195.613L191.891 62.1217L177.001 62.0141C177.001 62.0141 176.986 62.0079 176.986 61.9987C176.986 61.9895 176.992 61.9834 177.001 61.9834Z"
            fill="white"
          />
          <path
            d="M177.002 62.0141L162.112 62.1217L158.391 62.1524H154.669C152.186 62.1247 149.706 62.1032 147.223 62.0172C147.213 62.0172 147.207 62.011 147.207 62.0018C147.207 61.9926 147.213 61.9864 147.223 61.9864C149.706 61.8973 152.186 61.8758 154.669 61.8481H158.391L162.112 61.8789L177.002 61.9864C177.002 61.9864 177.017 61.9926 177.017 62.0018C177.017 62.011 177.011 62.0172 177.002 62.0172V62.0141Z"
            fill="white"
          />
          <path
            d="M176.986 61.9989L176.878 47.1094L176.851 43.3878V39.6662C176.878 37.1831 176.9 34.7031 176.986 32.22C176.986 32.2107 176.992 32.2046 177.001 32.2046C177.011 32.2046 177.017 32.2107 177.017 32.22C177.106 34.7031 177.127 37.1831 177.155 39.6662V43.3878L177.124 47.1094L177.017 61.9989C177.017 61.9989 177.011 62.0143 177.001 62.0143C176.992 62.0143 176.986 62.0081 176.986 61.9989Z"
            fill="white"
          />
          <path
            d="M177.017 61.9988L177.124 76.8882L177.152 80.6098V84.3314C177.124 86.8145 177.103 89.2946 177.017 91.7777C177.017 91.7869 177.011 91.7931 177.002 91.7931C176.992 91.7931 176.986 91.7869 176.986 91.7777C176.897 89.2946 176.876 86.8145 176.848 84.3314V80.6098L176.879 76.8882L176.986 61.9988C176.986 61.9988 176.992 61.9834 177.002 61.9834C177.011 61.9834 177.017 61.9895 177.017 61.9988Z"
            fill="white"
          />
          <path
            d="M176.989 61.9892L187.441 51.3837L190.053 48.7315L192.684 46.0978C194.457 44.3615 196.23 42.6252 198.047 40.9319C198.053 40.9257 198.062 40.9257 198.068 40.9319C198.074 40.938 198.074 40.9472 198.068 40.9534C196.375 42.7696 194.635 44.5397 192.902 46.316L190.268 48.9467L187.616 51.5588L177.011 62.0107C177.011 62.0107 176.995 62.0168 176.989 62.0107C176.983 62.0045 176.983 61.9953 176.989 61.9892Z"
            fill="white"
          />
          <path
            d="M177.011 62.0106L166.559 72.6161L163.947 75.2682L161.316 77.9019C159.543 79.6382 157.77 81.3746 155.953 83.0679C155.947 83.074 155.938 83.074 155.932 83.0679C155.926 83.0617 155.926 83.0525 155.932 83.0464C157.625 81.2301 159.365 79.46 161.098 77.6837L163.732 75.0531L166.384 72.4409L176.989 61.9891C176.989 61.9891 177.005 61.9829 177.011 61.9891C177.017 61.9952 177.017 62.0044 177.011 62.0106Z"
            fill="white"
          />
          <path
            d="M176.989 62.0107L166.384 51.5588L163.732 48.9467L161.098 46.316C159.362 44.5428 157.625 42.7696 155.932 40.9534C155.926 40.9472 155.926 40.938 155.932 40.9319C155.938 40.9257 155.947 40.9257 155.953 40.9319C157.77 42.6252 159.54 44.3646 161.316 46.0978L163.947 48.7315L166.559 51.3837L177.011 61.9892C177.011 61.9892 177.017 62.0045 177.011 62.0107C177.005 62.0168 176.995 62.0168 176.989 62.0107Z"
            fill="white"
          />
          <path
            d="M177.011 61.9895L187.616 72.4413L190.268 75.0535L192.902 77.6841C194.638 79.4573 196.375 81.2305 198.068 83.0468C198.074 83.0529 198.074 83.0621 198.068 83.0683C198.062 83.0744 198.053 83.0744 198.047 83.0683C196.23 81.375 194.46 79.6356 192.684 77.9023L190.053 75.2686L187.441 72.6165L176.989 62.011C176.989 62.011 176.983 61.9956 176.989 61.9895C176.995 61.9833 177.005 61.9833 177.011 61.9895Z"
            fill="white"
          />
          <path
            d="M176.995 61.9866L198.95 53.5969L204.439 51.4979L209.937 49.4236L215.453 47.4045L218.213 46.3965L220.982 45.4131C220.982 45.4131 220.997 45.4131 221 45.4223C221 45.4315 221 45.4377 220.991 45.4407L218.262 46.5286L215.524 47.592L210.044 49.7155L204.546 51.7868L199.039 53.8305L177.011 62.0174C177.011 62.0174 176.995 62.0174 176.992 62.0082C176.989 61.9989 176.992 61.9928 177.002 61.9897L176.995 61.9866Z"
            fill="white"
          />
          <path
            d="M177.005 62.0141L155.05 70.4039L149.561 72.5028L144.063 74.5772L138.547 76.5963L135.787 77.6043L133.018 78.5877C133.018 78.5877 133.003 78.5877 133 78.5785C133 78.5693 133 78.5631 133.009 78.56L135.738 77.4721L138.476 76.4088L143.956 74.2853L149.454 72.214L154.961 70.1703L176.989 61.9834C176.989 61.9834 177.005 61.9834 177.008 61.9926C177.008 62.0018 177.008 62.008 176.998 62.0111L177.005 62.0141Z"
            fill="white"
          />
          <path
            d="M176.986 62.0046L168.596 40.0499L166.497 34.5613L164.423 29.0634L162.404 23.5471L161.396 20.7874L160.413 18.0184C160.413 18.0184 160.413 18.0031 160.422 18C160.431 18 160.437 18 160.44 18.0092L161.528 20.7382L162.591 23.4764L164.715 28.9558L166.786 34.4537L168.83 39.9608L177.017 61.9892C177.017 61.9892 177.017 62.0046 177.008 62.0077C176.998 62.0077 176.992 62.0077 176.989 61.9984L176.986 62.0046Z"
            fill="white"
          />
          <path
            d="M177.014 61.9953L185.404 83.9499L187.503 89.4386L189.577 94.9365L191.596 100.453L192.604 103.212L193.587 105.981C193.587 105.981 193.587 105.997 193.578 106C193.569 106 193.563 106 193.56 105.991L192.472 103.262L191.409 100.523L189.285 95.044L187.214 89.5461L185.17 84.039L176.983 62.0106C176.983 62.0106 176.983 61.9953 176.992 61.9922C177.002 61.9922 177.008 61.9922 177.011 62.0014L177.014 61.9953Z"
            fill="white"
          />
          <path
            d="M176.986 61.9921L186.577 40.5352L188.974 35.1695L191.396 29.816L193.87 24.4872L195.108 21.8227L196.368 19.1675C196.368 19.1675 196.381 19.1552 196.39 19.1614C196.396 19.1645 196.399 19.1737 196.396 19.1798L195.234 21.8781L194.048 24.5671L191.676 29.942L189.251 35.2955L186.802 40.6366L177.014 62.0043C177.014 62.0043 177.001 62.0166 176.992 62.0105C176.986 62.0074 176.98 61.9982 176.986 61.989V61.9921Z"
            fill="white"
          />
          <path
            d="M177.014 62.008L167.422 83.4648L165.025 88.8305L162.604 94.184L160.13 99.5128L158.891 102.177L157.631 104.832C157.631 104.832 157.619 104.845 157.61 104.839C157.604 104.836 157.601 104.826 157.604 104.82L158.765 102.122L159.952 99.4329L162.324 94.058L164.749 88.7045L167.198 83.3634L176.986 61.9957C176.986 61.9957 176.998 61.9834 177.008 61.9895C177.014 61.9926 177.02 62.0018 177.014 62.011V62.008Z"
            fill="white"
          />
          <path
            d="M176.995 62.0138L155.539 52.4225L150.173 50.0254L144.819 47.6038L139.491 45.1299L136.826 43.8914L134.171 42.6314C134.171 42.6314 134.159 42.6191 134.165 42.6099C134.168 42.6037 134.177 42.6007 134.183 42.6037L136.881 43.7654L139.57 44.9516L144.945 47.3241L150.299 49.7488L155.64 52.1982L177.008 61.9862C177.008 61.9862 177.02 61.9985 177.014 62.0077C177.011 62.0138 177.002 62.02 176.992 62.0138H176.995Z"
            fill="white"
          />
          <path
            d="M177.008 61.9861L198.464 71.5774L203.83 73.9745L209.184 76.3962L214.512 78.8701L217.177 80.1085L219.832 81.3685C219.832 81.3685 219.844 81.3808 219.838 81.39C219.835 81.3962 219.826 81.3993 219.817 81.3962L217.119 80.2345L214.43 79.0483L209.055 76.6758L203.701 74.2511L198.36 71.8018L176.992 62.0138C176.992 62.0138 176.98 62.0015 176.986 61.9923C176.989 61.983 176.998 61.98 177.008 61.9861Z"
            fill="white"
          />
        </g>
        <path
          d="M203.528 55.375C196.138 60.1628 187.664 63.5383 178.42 65.048C169.176 66.5577 160.066 66.0586 151.538 63.8732"
          stroke="white"
          strokeWidth="25.3666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.441 35.8984C174.256 44.427 173.754 53.5339 175.266 62.7808C176.776 72.0247 180.152 80.4985 184.939 87.8887"
          stroke="white"
          strokeWidth="25.3666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M186.97 59.7397C186.97 59.7397 183.667 65.3098 176.107 65.1972C170.734 65.1181 168.458 61.6969 168.458 61.6969"
          stroke="#231F20"
          strokeWidth="2.47153"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178.764 55.0645C178.764 55.0645 179.653 58.3639 182.094 57.8221C184.535 57.2803 184.285 53.6309 184.285 53.6309"
          stroke="#231F20"
          strokeWidth="2.47153"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M175.769 55.5547C175.769 55.5547 175.979 58.9667 173.492 59.2285C171.005 59.4903 170.077 55.9534 170.077 55.9534"
          stroke="#231F20"
          strokeWidth="2.47153"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_95_8962"
          x="0"
          y="0"
          width="353"
          height="270"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_95_8962"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_95_8962"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_95_8962">
          <rect width="353" height="269.847" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
