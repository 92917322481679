import { GridDatum } from "./types";
import { useState, useEffect } from "react";

const useOpacity = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 0);
  }, []);

  return opacity;
};

const Cloud = ({ message }: { message: string }) => {
  const opacity = useOpacity();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 2658 1751"
      fill="none"
      style={{
        transition: "opacity .3s ease-in-out",
        opacity,
      }}
    >
      <path
        d="M1903.42 1301.31C2302.6 1228.87 2471.88 945.008 2465.23 723.243C2453.4 350.676 2074.18 137.781 1753.36 60.1634C1429.58 -18.1938 1080.67 -1.19179 763.549 100.82C489.299 189.527 101.948 375.81 76.8146 706.24C65.7263 861.476 146.301 1013.76 257.923 1116.51C557.307 1394.45 1055.54 1463.94 1442.15 1397.41C1459.15 1520.86 1414.06 1670.92 1318.7 1750.02C1562.64 1710.84 1806.59 1528.25 1903.42 1301.31Z"
        fill="black"
      />
      <path
        d="M1941.85 1321.3C2360.21 1271.05 2551.7 996.876 2557.85 775.175C2567.08 402.718 2184.87 169.932 1855.72 74.6006C1524.26 -20.7308 1160.5 -23.6867 825.199 61.2986C536.039 135.199 122.293 299.997 77.6884 628.853C56.9242 782.566 132.29 938.495 242.264 1047.87C537.576 1341.25 1051.3 1437.32 1457.35 1392.98C1467.35 1516.4 1411.98 1663.46 1308.93 1737.36C1564.25 1711.49 1828.03 1543 1941.85 1321.3Z"
        fill="white"
      />
      <path
        d="M2612.97 453.509C2593.75 421.723 2571.57 390.676 2547.92 361.107C2522.79 331.538 2497.65 304.926 2470.3 279.793C2439.99 253.181 2416.34 233.961 2383.07 211.785C2375.19 206.364 2367.3 200.943 2359.42 195.522C2357.45 194.536 2355.72 193.551 2354.24 192.565C2346.11 188.13 2358.68 195.522 2350.55 190.347C2346.61 187.883 2342.66 185.666 2338.72 183.694C2321.97 173.838 2304.96 164.721 2287.71 156.343C2276.63 150.43 2271.45 148.212 2260.36 143.037C2250.51 139.095 2240.65 135.152 2230.79 131.21C2211.58 123.818 2190.88 116.425 2170.92 110.512C2159.09 106.816 2145.78 99.4235 2139.13 88.3352C2133.96 79.4646 2136.91 71.3332 2148 75.0293C2311.37 123.818 2460.69 219.916 2571.57 349.279C2602.62 384.762 2629.97 422.462 2655.11 463.119C2660.28 471.99 2655.85 478.643 2645.5 476.425C2633.67 473.468 2619.62 464.598 2612.97 453.509Z"
        fill="#272525"
      />
      <path
        d="M58.417 505.358C76.5054 472.915 97.5749 441.106 120.174 410.722C144.251 380.287 168.431 352.807 194.881 326.726C224.233 299.063 247.196 279.022 279.659 255.688C287.349 249.993 295.038 244.298 302.727 238.602C304.662 237.548 306.351 236.502 307.794 235.465C315.764 230.746 303.466 238.576 311.41 233.119C315.263 230.517 319.125 228.162 322.996 226.053C339.394 215.613 356.064 205.903 373.007 196.923C383.88 190.623 388.974 188.224 399.873 182.662C409.584 178.375 419.295 174.088 429.007 169.801C447.954 161.736 468.379 153.62 488.117 147.007C499.807 142.897 512.845 135.041 519.103 123.725C523.962 114.678 520.721 106.655 509.77 110.74C348.222 165.251 202.376 266.548 96.1172 399.735C66.3387 436.289 40.3322 474.929 16.646 516.446C11.7871 525.493 16.4539 531.986 26.7185 529.405C38.4345 526.034 52.1586 516.674 58.417 505.358Z"
        fill="#272525"
      />

      <foreignObject x="15%" y="15%" height="70%" width="70%">
        <div className="line-clamp-4" style={{ fontSize: `9rem` }}>
          “{message}”
        </div>
      </foreignObject>
    </svg>
  );
};

const Quadrant = ({ datum }: { datum: GridDatum }) => {
  const opacity = useOpacity();

  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <div
        className="flex-none relative"
        style={{ height: "50%", aspectRatio: 1.43 }}
      >
        <Cloud message={datum.text} />
      </div>

      {/* AVATAR */}
      <div
        className="rounded-full flex-none border-amber-400 border-4 overflow-hidden shadow-2xl"
        style={{
          height: "30%",
          aspectRatio: 1,
          marginLeft: "-15%",
          transition: "opacity .3s ease-in-out",
          opacity,
        }}
      >
        <img
          src={datum.image}
          className="h-full w-full"
          style={{
            transition: "opacity .3s ease-in-out",
            opacity,
          }}
        />
      </div>
    </div>
  );
};

export const Quadrants = ({ data }: { data: GridDatum[] }) => {
  return (
    <div
      className="absolute inset-0 grid gap-1 grid-rows-2 grid-cols-3"
      style={{
        gridTemplateColumns: "2fr 1fr 2fr",
      }}
    >
      {data[0] ? <Quadrant datum={data[0]} key={data[0].id} /> : <div />}
      <div />
      {data[1] ? <Quadrant datum={data[1]} key={data[1].id} /> : <div />}
      {data[2] ? <Quadrant datum={data[2]} key={data[2].id} /> : <div />}
      <div />
      {data[3] ? <Quadrant datum={data[3]} key={data[3].id} /> : <div />}
    </div>
  );
};
