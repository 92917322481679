import { ChangeEvent, useEffect, useRef, useState } from "react";

import { VscAdd } from "react-icons/vsc";
import { useUploadImage } from "./hooks/useUploadImage";
import { createFeedback, fetchStatus } from "../api-utils/utils";
import { Overlay } from "../app2/Overlay";
import Modal from "./Modal";
import { useLocalStorage } from "react-use";
import { Loader } from "./Loader";
import { useQuery } from "@tanstack/react-query";

const MAX_WORDS = 140;

const enabledStateClasses = "bg-black";
const disabledStateClasses = "bg-slate-400";

const EVENT_END_MESSAGE =
  "We appreciate your participation! The pledge wall has reached its capacity, and no more images can be added.";
const FORM_SUBMIT_MESSAGE = `THANKYOU FOR SUBMITTING YOUR PLEDGE. LOOK AT THE SCREEN FOR A
SURPRISE!`;

const getWordCount = (val: string) => val.split(" ").filter(Boolean).length;

export default function App() {
  const ref = useRef<HTMLInputElement>(null);

  const [isEventEnded, setIsEventEnded] = useLocalStorage(
    "@form/isEventEnded",
    false
  );

  const { data } = useQuery({
    queryKey: ["status"],
    queryFn: () => fetchStatus(),
    refetchInterval: 10000,
    enabled: !isEventEnded,
  });

  const eventEnded = data?.is_end;

  useEffect(() => {
    if (eventEnded) {
      setIsEventEnded(true);
    }
  }, [eventEnded]);

  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>();
  // const wordCount = getWordCount(description);

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const currentVal = e.target.value;
    // const newWordCount = getWordCount(currentVal);
    if (currentVal.length <= MAX_WORDS) {
      setDescription(currentVal);
    }
  };

  const { currentImageUrl, handleChange, isUploading } = useUploadImage();

  const disabled = !currentImageUrl || !description || isUploading;

  const buttonClassNames = `w-full h-10 rounded-md text-base tracking-widest font-medium text-white ${
    disabled ? disabledStateClasses : enabledStateClasses
  }`;

  const [isModalOpen, setIsModalOpen] = useLocalStorage(
    "@form/modalState",
    false
  );

  const modalMessage = isEventEnded ? EVENT_END_MESSAGE : FORM_SUBMIT_MESSAGE;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      await createFeedback({ description, imageUrl: currentImageUrl! });
      setIsModalOpen(true);
    } catch (err) {
      setError("There was an error submitting. ");
    }
    setLoading(false);
  };

  return (
    <div className="h-dvh bg-orange-100 flex justify-center overflow-y-auto relative">
      <div
        className={`relative w-full max-w-xl bg-amber-400 min-h-dvh h-fit flex flex-col items-center gap-10 py-4 bg-mobile-bg ${
          isModalOpen ? "blur-sm" : ""
        }`}
      >
        {/* <img className="absolute inset-0 h-dvh w-full z-0" src="/form-bg.png" /> */}
        <img
          src="/logo.png"
          className="w-64 h-16 z-10"
          style={{ objectFit: "cover" }}
        />
        <h4 className="text-lg font-medium z-10">UPLOAD YOUR PHOTOGRAPH</h4>
        <div className="bg-slate-400 rounded-full h-[180px] w-[180px] p-3 flex-none z-10">
          <div
            className="relative rounded-full h-full w-full bg-black flex items-center justify-center cursor-pointer"
            onClick={() => ref.current?.click()}
          >
            {currentImageUrl ? (
              <>
                <Overlay className="rounded-full opacity-40" />
                <img
                  src={currentImageUrl}
                  className="h-full w-full rounded-full"
                />
                {!isUploading && (
                  <p className="absolute text-white text-xs top-2/3 left-1/2 -translate-x-1/2 -translate-y-1/2 text-nowrap">
                    TAP TO CHANGE
                  </p>
                )}
              </>
            ) : (
              !isUploading && (
                <VscAdd
                  size={50}
                  stroke="white"
                  strokeWidth="0.5"
                  fill="white"
                />
              )
            )}
            {isUploading ? (
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            ) : null}
            <input
              type="file"
              ref={ref}
              className="w-0 h-0"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </div>
        </div>
        <h4 className="text-lg font-medium z-10">YOUR PLEDGE FOR 2024</h4>
        <div className="w-full px-5 relative z-10">
          <textarea
            placeholder="One thing you will do to make Cremaffin Plus 200+cr brand"
            className="w-full resize-none rounded z-10 relative outline-none pt-3 px-4 pb-4 text-base leading-5 font-medium	"
            rows={8}
            value={description}
            onChange={onDescriptionChange}
          />
          <div
            className="absolute bottom-0 z-20 leading-4 font-medium	"
            style={{ right: "30px", bottom: "10px", fontSize: "10px" }}
          >
            {description.length}/{MAX_WORDS} characters
          </div>
          <div
            className="absolute bg-black h-2 z-0 rounded"
            style={{ bottom: "3px", left: "20px", right: "20px" }}
          />
        </div>
        {!!error && (
          <div className="w-full px-5 text-red-700 z-10">
            <div className="w-full text-center">{error}</div>
          </div>
        )}
        <div className="w-full px-5 z-10">
          <button
            className={buttonClassNames}
            disabled={disabled}
            onClick={onSubmit}
          >
            SUBMIT
          </button>
        </div>
      </div>

      {isModalOpen || isEventEnded ? <Modal content={modalMessage} /> : null}
    </div>
  );
}
