import { useEffect, useMemo, useRef } from "react";
import { GridDatum } from "./types";
import { Grid } from "./Grid";

export const FilledGrid = ({
  randomizedData,
  plainData,
}: {
  randomizedData: (GridDatum | undefined)[];
  plainData: GridDatum[];
}) => {
  const randomizedDataRef = useRef(randomizedData);
  const adaptedData = useMemo(() => {
    return randomizedDataRef.current.map((datum) => {
      if (datum) {
        return datum;
      }
      const randomIdx = Math.floor(Math.random() * plainData.length);
      return plainData[randomIdx];
    });
  }, [plainData]);

  return <Grid data={adaptedData} />;
};
