import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "./Router";
const rootElement = document.getElementById("root")!;
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(rootElement);

// eslint-disable-next-line no-constant-condition
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </React.StrictMode>
);
