import { useState } from "react";
import { getImageUploadUrl } from "../../api-utils/utils";

export const useUploadImage = () => {
  const [currentImageUrl, setCurrentImageUrl] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);

  const onUploadFile = async (file: File) => {
    setIsUploading(true);

    // const formData = new FormData()
    // formData.append('file', file)
    // HERE WE WILL GET URL OF THE S3 BUCKET
    try {
      const { url } = await getImageUploadUrl();

      await fetch(url, {
        method: "PUT",
        body: file,
      });

      const urlObj = new URL(url);
      urlObj.search = "";
      const sanitizedURL = urlObj.toString();

      setCurrentImageUrl(sanitizedURL);
    } catch (err) {
      console.error("Got error", err);
    }
    setIsUploading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) onUploadFile(file);
  };

  return {
    handleChange,
    isUploading,
    currentImageUrl,
  };
};
