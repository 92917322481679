import { useEffect, useMemo } from "react";
import _invert from "lodash/invert";
import _keyBy from "lodash/keyBy";
import _difference from "lodash/difference";
import { useQuery } from "@tanstack/react-query";
import { fetchFeedbacks } from "../../api-utils/utils";

import useLocalStorage from "react-use/lib/useLocalStorage";
import useLatest from "react-use/lib/useLatest";
import { GridDatum } from "../types";

const RANDOMIZED_STATE_KEY = "@grid/randomIndices";
const HIGHLIGHT_STATE_KEY = "@quad/highlightedIds";
const GRID_MOCK_ARRAY = Array(400)
  .fill(1)
  .map((_, idx) => idx);

const getRandomIdx = (count: number, occupiedIndices: number[]): number => {
  const choices = _difference(GRID_MOCK_ARRAY, occupiedIndices);
  const random = Math.floor(Math.random() * choices.length);
  return choices[random];
};

const EMPTY_ARRAY = [] as GridDatum[];

export const useApp = () => {
  const {
    data: fetchedData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["list"],
    queryFn: () => fetchFeedbacks(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 3000,
  });

  const records = fetchedData?.data;
  const is_end = fetchedData?.is_end;
  const data = useMemo(
    () => (records ? [...records].sort((a, b) => a.id - b.id) : records),
    [records]
  );

  // data id vs grid idx
  const [indexMapping, setIndexMapping] = useLocalStorage<
    Record<number, number>
  >(RANDOMIZED_STATE_KEY, {});

  const [highlightedIds, setHighlightedIds] = useLocalStorage<
    Record<number, boolean>
  >(HIGHLIGHT_STATE_KEY, {});

  const latestIndexMapping = useLatest(indexMapping);

  useEffect(() => {
    const totalItems = data?.length;

    if (
      !totalItems ||
      totalItems === Object.keys(latestIndexMapping.current ?? {}).length ||
      totalItems === 400
    ) {
      return;
    }

    const newMapping = { ...latestIndexMapping.current };
    for (let i = 0; i < totalItems; i++) {
      const occupiedIndices = Object.values(newMapping);

      if (newMapping[data[i].id] === undefined) {
        const randomIdx = getRandomIdx(400, occupiedIndices);
        newMapping[data[i].id] = randomIdx;
      }
    }
    setIndexMapping(newMapping);
  }, [data]);

  const randomizedGridData = useMemo(() => {
    if (!data) {
      return Array(400)
        .fill(1)
        .map(() => undefined);
    }

    const idxVsDataId = _invert(indexMapping ?? {});
    const dataById = _keyBy(data, "id");

    return Array(400)
      .fill(1)
      .map((_, idx) => {
        if (idxVsDataId[idx] !== undefined) {
          return dataById[idxVsDataId[idx]];
        }
        return undefined;
      });
  }, [data, indexMapping]);

  const spotlightData = useMemo(() => {
    if (!data) {
      return [];
    }

    const newData = data
      .filter((datum) => (highlightedIds ?? {})[datum.id] === undefined)
      .slice(0, 4);

    const adaptedData = newData.length < 4 ? data.slice(-4) : newData;

    return adaptedData;
  }, [data, highlightedIds]);

  const deps = spotlightData.map((datum) => datum.id).join("||");

  const latestHighlights = useLatest(highlightedIds);

  useEffect(() => {
    if (!deps) {
      return;
    }
    const ids = deps.split("||").map(Number);

    setTimeout(() => {
      const newHighlights = ids.reduce(
        (acc, id) => ({ ...acc, [id]: true }),
        latestHighlights.current
      );

      setHighlightedIds(newHighlights);
    }, 3000);
  }, [deps]);

  return {
    plainData: data ?? EMPTY_ARRAY,
    randomizedGridData,
    spotlightData,
    isLoading,
    error,
    complete: is_end,
  };
};
