import { Grid } from "./Grid";
import { Overlay } from "./Overlay";
import { Sausage } from "./Sausage";
import { Quadrants } from "./Quadrants";
import { useApp } from "./hooks/useApp";
import { FilledGrid } from "./FilledGrid";

export default function App() {
  const {
    plainData,
    randomizedGridData,
    spotlightData,
    isLoading,
    error,
    complete,
  } = useApp();

  if (complete) {
    return (
      <div className="h-dvh w-dvw relative">
        <FilledGrid randomizedData={randomizedGridData} plainData={plainData} />
        {/* <Overlay /> */}
        <Sausage />
      </div>
    );
  }
  return (
    // <div className="h-dvh w-dvw border-amber-300 border-solid border-4"></div>
    <div className="h-dvh w-dvw relative">
      <Grid data={randomizedGridData} />
      {/* <Overlay /> */}
      <Sausage />
      {spotlightData.length < 4 ? null : <Quadrants data={spotlightData} />}
    </div>
  );
}
