import { GridDatum } from "./types";

export const Grid = ({ data }: { data: (GridDatum | undefined)[] }) => {
  return (
    <div
      className="h-full w-full grid"
      style={{
        gridTemplateRows: "repeat(16, minmax(0, 1fr))",
        gridTemplateColumns: "repeat(25, minmax(0, 1fr))",
      }}
    >
      {data.map((datum, idx) => {
        if (datum) {
          return (
            <div key={idx}>
              <img src={datum.image} className="h-full w-full" />
            </div>
          );
        }

        return (
          <div key={idx}>
            <div className="h-full w-full bg-amber-400" />
          </div>
        );
      })}
    </div>
  );
};
