import { GridDatum } from "../app2/types";
import { axiosClient } from "./axiosClient";

export const getImageUploadUrl = async (): Promise<{ url: string }> => {
  const res = await axiosClient.get("api/feedback/get_s3_url");
  return res.data;
};

export const createFeedback = async ({
  description,
  imageUrl,
}: {
  description: string;
  imageUrl: string;
}) => {
  return await axiosClient.post("api/feedback", {
    text: description,
    image: imageUrl,
  });
};

export const fetchFeedbacks = async (): Promise<{
  data: GridDatum[];
  is_end: boolean;
}> => {
  return axiosClient.get("api/feedback").then((res) => res.data);
};

export const fetchStatus = async (): Promise<{
  is_end: boolean;
}> => {
  return axiosClient.get("api/feedback/is_day_end").then((res) => res.data);
};
